import { useState} from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { bgGradient } from '../theme/css';
import { useRouter } from '../routes/hooks';

import { ReactComponent as MySVG } from '../wowizer_logo.svg';
import { resetPasswordVerify } from 'src/services/apis';
import { useParams } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function PasswordResetVerifyView() {
  const theme = useTheme();
  const router = useRouter();
  const { token, userId } = useParams(); 
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!password || !confirmPassword) {
      setError('Please fill in all fields.');
      setOpenError(true);
      return;
    }
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      setOpenError(true);
      return;
    }
    setLoading(true);
    try {
      await resetPasswordVerify(userId, token, password);
      setLoading(false);
      setOpenSuccess(true);
      setTimeout(() => {
        router.push('/login');
      }, 3000); // Redirect after 3 seconds
    } catch (err) {
      setError('Failed to reset password. Please try again.');
      setOpenError(true);
      setLoading(false);
    }
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
    router.push('/login');
  };

  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.9),
          imgUrl: '/assets/overlay_4.jpg',
        }),
        height: 1,
      }}
    >
      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card 
          sx={{
            p: 5,
            width: 1,
            maxWidth: 420,
          }}
        >
          <Box
            component="div"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 1,
            }}
          >
            <Typography variant="h4">
              Reset Password
            </Typography>
          </Box>
          <Box
            component="div"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <MySVG />
          </Box>
          <Box
            component="div"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="body2" sx={{ mt: 2, mb: 5 }}>
              Enter your new password below.
            </Typography>
          </Box>

          <form onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                name="password"
                label="New Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
              />
              <TextField
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                fullWidth
              />
            </Stack>

            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={{ mt: 3 }}
              disabled={loading}
            >
              {loading ? 'Resetting...' : 'Reset Password'}
            </Button>
          </form>

          <Dialog open={openError} onClose={handleCloseError}>
            <DialogTitle>Error</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {error}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseError} color="primary" autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={openSuccess} onClose={handleCloseSuccess}>
            <DialogTitle>Success</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Your password has been successfully reset. You will be redirected to the login page shortly.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseSuccess} color="primary" autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      </Stack>
    </Box>
  );
}
