import { chatApi } from 'src/services/apis';

class ActionProvider {
  constructor(
    createChatBotMessage,
    setStateFunc,
    createClientMessage,
    stateRef,
    createCustomMessage,
    ...rest
  ) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
    this.createClientMessage = createClientMessage;
    this.stateRef = stateRef;
    this.createCustomMessage = createCustomMessage;
  }

  handleAPIRequest = async (userMessage) => {
    
    const typingMessage = this.createChatBotMessage('Typing...');
    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, typingMessage],
    }));

    const data = {
      "prompt": userMessage
    };
    
    
  
    try {
      const response = await chatApi(data);
      const messageContent = response.data.content;
      const botMessage = this.createChatBotMessage(messageContent);
  
      this.setState((prevState) => ({
        ...prevState,
        messages: prevState.messages.filter((msg) => msg !== typingMessage).concat(botMessage),
      }));
      
    } catch (error) {
      console.error(error);
    }
  }
  
}

export default ActionProvider;