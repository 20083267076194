import { createChatBotMessage } from "react-chatbot-kit";
import ChatBotHeader from "./ChatCustomHeader";

const config = {
  botName: "WoWizer ChatBOT ",
  initialMessages: [createChatBotMessage(`Hello! How can I assist you today?`)],
  customComponents: {
    header: () => <ChatBotHeader />, // Use your custom header
  },
  customStyles: {
    botMessageBox: {
      backgroundColor: "#3D4A9A",
    },
    chatButton: {
      backgroundColor: "#3D4A9A",
    },
  },
};

export default config;
