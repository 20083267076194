import React, { useState, useEffect, useCallback } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Document, Page, pdfjs } from 'react-pdf';
import { getExtractedData, getUserFiles, getExtractedFileDownload, getExtractedJson } from 'src/services/apis';
import axios from 'axios';
import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-json-view-lite/dist/index.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import EditableTable from 'src/components/EditableTable';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function EntityExtractionPage() {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [extractloading, setExtractLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [pdfselectedFile, setPDFSelectedFile] = useState(null);
  const [excelselectedFile, setExcelSelectedFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [inputText, setInputText] = useState('');
  const [responseText, setResponseText] = useState("");
  const [isExtracting, setIsExtracting] = useState(false);
  const [extractedMatch, setExtractedMatch] = useState(null);
  const [maintabIndex, setMainTabIndex] = useState(1);
  const [jsontabIndex, setJsonTabIndex] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [jsondata, setJsonData] = useState({});
  const [sectionTabIndex, setSectionTabIndex] = useState(0);
  const [showExtractedContent, setShowExtractedContent] = useState(false);
  const [isExtractButtonDisabled, setIsExtractButtonDisabled] = useState(false);

  useEffect(() => {
    const fetchUserFiles = async () => {
      try {
        const data = await getUserFiles();
        setFiles(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserFiles();
  }, []);

  const highlightPattern = (text, pattern) => {
    const modifiedPattern = pattern.replace(/_/g, ' ');
    const words = modifiedPattern.split(' ');
    const regex = new RegExp(`(${words.join('|')})`, 'gi');
    return text.replace(regex, (value) => `<mark>${value}</mark>`);
  };

  const handleReset = () => {
    window.location.reload();
  };

  const textRenderer = useCallback(
    (textItem) => highlightPattern(textItem.str, searchText),
    [searchText]
  );

  const handleFileSelect = async (event) => {
    const fileId = event.target.value;
    const file = files.find((file) => file.id === fileId);
    setSelectedFile(file);
    setPageNumber(1);
    if (file) {
      try {
        const response = await axios.get(file.file_url, {
          responseType: 'blob',
        });
        setPdfData(URL.createObjectURL(response.data));
      } catch (error) {
        setError(error);
      }
    }
  };

  const handlePDFFileSelect = async (event) => {
    const fileId = event.target.value;
    const file = files.find((file) => file.id === fileId);
    setPDFSelectedFile(file);
    setSelectedFile(file);
    setPageNumber(1);
    if (file) {
      try {
        const response = await axios.get(file.file_url, {
          responseType: 'blob',
        });
        setPdfData(URL.createObjectURL(response.data));
      } catch (error) {
        setError(error);
      }
    }
  };

  const handleSave = (section, updatedData) => {
    const newData = { ...jsondata, [section]: { extract_data: updatedData } };
    setJsonData(newData);
  };

  const handleExcelFileSelect = async (event) => {
    const fileId = event.target.value;
    const file = files.find((file) => file.id === fileId);
    setExcelSelectedFile(file);
  };

  const handleExcelDownloadSubmit = async (event) => {
    if (pdfselectedFile && excelselectedFile) {
      try {
        setExtractLoading(true);
        const response = await getExtractedFileDownload(jsondata);
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'extracted_file.xlsx';
        if (contentDisposition) {
          const matches = /filename=([^;]+)/.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].trim();
          }
        }
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setExtractLoading(false);
      } catch (error) {
        setExtractLoading(false);
        console.error('Error extracting file:', error);
      }
    } else {
      setExtractLoading(false);
      console.log('Please select both PDF and Excel files.');
    }
  };

  const handleExtractJsonSubmit = async (event) => {
    if (pdfselectedFile && excelselectedFile) {
      try {
        setExtractLoading(true);
        setIsExtractButtonDisabled(true)
        const response = await getExtractedJson(pdfselectedFile.id, excelselectedFile.id);
        setJsonData(response.data['exported_json']);
        // setExtractedMatch(response.data['exported_json']['Basic Information']['extracted_match']);
        setShowExtractedContent(true);
        setExtractLoading(false);
      } catch (error) {
        setExtractLoading(false);
        setIsExtractButtonDisabled(false);
        console.error('Error extracting file:', error);
      }
    } else {
      setExtractLoading(false);
      console.log('Please select both PDF and Excel files.');
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const goToPreviousPage = () => {
    setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 1, 1));
  };

  const goToNextPage = () => {
    setPageNumber((prevPageNumber) => Math.min(prevPageNumber + 1, numPages));
  };

  const handlePageInputChange = (event) => {
    const pageNumber = parseInt(event.target.value, 10);
    if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= numPages) {
      setPageNumber(pageNumber);
    }
  };

  const handleSubmit = async () => {

    if (!inputText.trim()) {
      alert('Input cannot be empty. Please enter the comma-separated list of values to extract.');
      return;
    }
    setIsExtracting(true);
    try {
      const data = await getExtractedData(selectedFile.id, inputText);
      setResponseText(data.data.extract_data);
      setExtractedMatch(data.data.extracted_match);
    } catch (err) {
      console.error(err);
    } finally {
      setIsExtracting(false);
    }
  };

  const handlePageNumberClick = (pageNumber, search_val, search_key) => {
    setPageNumber(pageNumber);
    setSearchText(search_val + " " + search_key);
  };

  const handleJsonTabChange = (event, newValue) => {
    setJsonTabIndex(newValue);
  };
  const handleMainTabChange = (event, newValue) => {
    setMainTabIndex(newValue);
  };
  
  const handleSectionTabChange = (event, newValue) => {
    setSectionTabIndex(newValue);
  };

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (error) {
    return <Typography variant="h6" color="error">Error loading files</Typography>;
  }

  return (
    <Container>
      <Tabs value={maintabIndex} onChange={handleMainTabChange}>
        <Tab label="Entity Extraction to JSON" />
        <Tab label="Entity Extraction to EXCEL" />
      </Tabs>
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
        {maintabIndex === 0 && (
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: '50%', pr: 2 }}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1">Select a file to display:</Typography>
                <Select value={selectedFile ? selectedFile.id : ''} onChange={handleFileSelect} displayEmpty>
                  <MenuItem value="" disabled>Select a file</MenuItem>
                  {files.filter(file => file.file_name.toLowerCase().endsWith('.pdf')).map((file) => (
                    <MenuItem key={file.id} value={file.id}>{file.file_name}</MenuItem>
                  ))}
                </Select>
              </Box>
              {selectedFile && (
                <Box
                  sx={{
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    overflow: 'auto',
                    maxHeight: '80vh',
                    boxShadow: 3,
                    p: 2,
                  }}
                >
                  {pdfData ? (
                    <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
                      <Document file={pdfData} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber} customTextRenderer={textRenderer} />
                      </Document>
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                        <Button onClick={goToPreviousPage} disabled={pageNumber <= 1}>Previous</Button>
                        <TextField
                          type="number"
                          value={pageNumber}
                          onChange={handlePageInputChange}
                          inputProps={{ min: 1, max: numPages }}
                          sx={{ mx: 2, width: 80 }}
                        />
                        <Typography variant="body1">{`of ${numPages}`}</Typography>
                        <Button onClick={goToNextPage} disabled={pageNumber >= numPages}>Next</Button>
                      </Box>
                    </Box>
                  ) : (
                    <Typography variant="h6">Loading PDF...</Typography>
                  )}
                </Box>
              )}
            </Box>
            {pdfData && (
              <Box sx={{ width: '50%', pl: 2 }}>
                <Typography variant="h6">Enter the List to Extract</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  sx={{ mb: 2 }}
                />
                <Button variant="contained" onClick={handleSubmit} disabled={isExtracting}>
                  {isExtracting ? <CircularProgress size={24} /> : 'Extract'}
                </Button>
                {responseText && (
                  <Box sx={{ mt: 2 }}>
                    <Tabs value={jsontabIndex} onChange={handleJsonTabChange}>
                      <Tab label="JSON Response" />
                      <Tab label="Extracted Links" />
                    </Tabs>
                    <Box sx={{ mt: 2 }}>
                      {jsontabIndex === 0 && (
                        <Box>
                          <Typography variant="subtitle1">Response:</Typography>
                          <JsonView data={responseText} shouldExpandNode={allExpanded} style={defaultStyles} />
                        </Box>
                      )}
                      {jsontabIndex === 1 && extractedMatch && (
                        <Box>
                          <Typography variant="subtitle1">Extracted Matches:</Typography>
                          {Object.entries(extractedMatch).map(([key, value]) => (
                            <Box key={key} sx={{ mb: 1, backgroundColor: '#f0f0f0', p: 1, borderRadius: 1 }}>
                              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{key}:</Typography>
                              <Typography variant="body2">{value.value}</Typography>
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1 }}>
                                {value.page_numbers.map((pageNum) => (
                                  <Button
                                    key={pageNum}
                                    variant="outlined"
                                    sx={{ mr: 1, mb: 1 }}
                                    onClick={() => handlePageNumberClick(pageNum, value.value, key)}
                                  >
                                    Page {pageNum}
                                  </Button>
                                ))}
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )}
        {maintabIndex === 1 && (
          <Box>
            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle2" style={{ display: 'inline', marginRight: '10px' }}>Select a PDF file :</Typography>
                <Select value={pdfselectedFile ? pdfselectedFile.id : ''} onChange={handlePDFFileSelect} displayEmpty>
                  <MenuItem value="" disabled>Select a PDF file</MenuItem>
                  {files.filter(file => file.file_name.toLowerCase().endsWith('.pdf')).map((file) => (
                    <MenuItem key={file.id} value={file.id}>{file.file_name}</MenuItem>
                  ))}
                </Select>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle2" style={{ display: 'inline', marginRight: '10px' }}>Select an Excel file :</Typography>
                <Select value={excelselectedFile ? excelselectedFile.id : ''} onChange={handleExcelFileSelect} displayEmpty>
                  <MenuItem value="" disabled>Select an Excel file</MenuItem>
                  {files.filter(file => file.file_name.toLowerCase().endsWith('.xlsx') || file.file_name.toLowerCase().endsWith('.xls')).map((file) => (
                    <MenuItem key={file.id} value={file.id}>{file.file_name}</MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button variant="contained" color="primary" onClick={handleExtractJsonSubmit} disabled={extractloading || isExtractButtonDisabled}>
                {extractloading ? <CircularProgress size={24} /> : 'Extract Json Values'}
              </Button>

              <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleReset}
                  sx={{ ml: 2 }}
                  >
                  Reload
              </Button>
            </Box>
            {showExtractedContent && (
              <Box sx={{ mt: 4 }}>
                <Tabs
                  value={sectionTabIndex}
                  onChange={handleSectionTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  {Object.keys(jsondata).map((section, index) => (
                    <Tab key={index} label={section} />
                  ))}
                </Tabs>
                {Object.keys(jsondata).map((section, index) => (
                  sectionTabIndex === index && (
                    <EditableTable
                      key={section}
                      title={section}
                      data={jsondata[section].extract_data}
                      onSave={handleSave}
                      pdfData={pdfData}
                      extractedMatch={jsondata[section].extracted_match}
                    />
                  )
                ))}
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <Button variant="contained" color="primary" onClick={handleExcelDownloadSubmit} disabled={extractloading}>
                    {extractloading ? <CircularProgress size={24} /> : 'Export Excel'}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Container>
  );
}
