import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';
import ProtectedRoute from 'src/components/ProtectedRoute';
import ChatbotPage from 'src/pages/chatbot';
import EmailConfirmView from 'src/pages/email_account_verify';
import EntityExtractionPage from 'src/pages/entity_extraction';
import FileManagementPage from 'src/pages/files';
import ForgotPasswordView from 'src/pages/forgot_password';
import PasswordResetVerifyView from 'src/pages/forgot_password_verify';

import DashboardLayout from 'src/pages/layouts/dashboard';
import SummarizerPage from 'src/pages/summarizer';
import VendorExcelFormsPage from 'src/pages/vendor-excel-forms';

// export const IndexPage = lazy(() => import('src/pages/app'));
export const UserPage = lazy(() => import('src/pages/users'));
export const LoginPage = lazy(() => import('src/pages/login'));
export const RegisterPage = lazy(() => import('src/pages/register'));
export const ProfilePage = lazy(() => import('src/pages/profile'));

// export const ProductsPage = lazy(() => import('src/pages/products'));
export const Page404 = lazy(() => import('src/pages/page-not-found'));

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      element: (
      
          <ProtectedRoute>
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
        </ProtectedRoute>
     
      ),
      children: [
        { element:  <ChatbotPage /> },
        { path: 'dashboard', element: <ChatbotPage />, index: true},
        { path: 'profile', element: <ProfilePage />},
        { path: 'chatbot', element: <ChatbotPage /> },
        { path: 'usermanagement', element: <UserPage /> },
        { path: 'filemanagement', element: <FileManagementPage /> },
        { path: 'entityextract', element: <EntityExtractionPage /> },
        { path: 'summary', element: <SummarizerPage /> },
        { path: 'vendor', element: <VendorExcelFormsPage /> },
        // { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: '/',
      element: <LoginPage />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      path: 'forgot-password',
      element: <ForgotPasswordView />,
    },
    {
      path: 'forgot-password-verify/:userId/:token/',
      element: <PasswordResetVerifyView />,
    },
    {
      path: 'email-account-verify/:token/',
      element: <EmailConfirmView />,
    },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
