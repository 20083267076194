import React, { createContext, useState, useContext, useEffect,useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import apiClient from 'src/services/apiClient';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {jwtDecode} from 'jwt-decode';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const isTokenExpired = (token) => {
  if (!token) return true;
  const { exp } = jwtDecode(token);
  return Date.now() >= exp * 1000;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(localStorage.getItem('user')?localStorage.getItem('user'):null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const refreshAccessToken = useCallback(async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken || isTokenExpired(refreshToken)) {
      logout();
      return null;
    }

    try {
      const response = await apiClient.post('/token/refresh/', { refresh: refreshToken });
      const { access } = response.data;
      localStorage.setItem('accessToken', access);
      return access;
    } catch (error) {
      console.error('Token refresh failed:', error);
      logout();
      return null;
    }
  }, []);

  useEffect(() => {
    const initializeAuth = async () => {
      const loggedInUser = localStorage.getItem('user');
      let accessToken = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');

      if (loggedInUser && accessToken && refreshToken) {
        if (isTokenExpired(accessToken)) {
          accessToken = await refreshAccessToken();
        }
        if (accessToken) {
          setUser(JSON.parse(loggedInUser));
        }
      }
    };

    initializeAuth();
  }, [refreshAccessToken]);
  const login = async (username, password) => {
    setLoading(true);
    try {
      const response = await apiClient.post('/token/', { "email": username, password });
      const { access, refresh, user } = response.data;
      setUser(user);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('accessToken', access);
      localStorage.setItem('refreshToken', refresh);
      navigate('/dashboard');
    } catch (error) {
      console.error('Login failed:', error);
      throw error; 
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
      <Backdrop open={loading} style={{ zIndex: 1300 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </AuthContext.Provider>
  );
};