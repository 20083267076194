import React from 'react';
import styled from 'styled-components';
import SvgColor from 'src/components/svg-color';

const Header = styled.div`
  display: flex;
  align-items: center;
  background-color: #3D4A9A;
  padding: 10px;
  font-size: 1.2rem;
  color: white;
  text-align: center;
`;

const IconWrapper = styled.div`
  margin-right: 10px;
  width: 24px;
  height: 24px;
`;

const ChatBotHeader = () => {
  return (
    <Header>
      <IconWrapper>
        <SvgColor src="/assets/icons/navbar/ic_chat.svg" sx={{ width: '100%', height: '100%' }} />
      </IconWrapper>
      WoWizer ChatBot
    </Header>
  );
};

export default ChatBotHeader;
