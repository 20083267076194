import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const navConfig = [
  {
    title: 'Chat Bot',
    path: '/chatbot',
    icon: icon('ic_chat'),
  },
  {
    title: 'File management',
    path: '/filemanagement',
    icon: icon('ic_files'),
  }, 
  {
    title: 'Entity Extraction',
    path: '/entityextract',
    icon: icon('ic_searchdoc'),
  }, 
  {
    title: 'Summarizer',
    path: '/summary',
    icon: icon('ic_summary'),
  }, 
  // {
  //   title: 'dashboard',
  //   path: '/',
  //   icon: icon('ic_analytics'),
  // },
  {
    title: 'user management',
    path: '/usermanagement',
    icon: icon('ic_user'),
  }
];

export default navConfig;
