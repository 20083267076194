import { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress, TextField, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import Iconify from 'src/components/iconify';
import { TrainData, UploadUserFile, delUserFile, getUserFiles, updateFileMetadata } from 'src/services/apis';

// ----------------------------------------------------------------------



export default function FileManagementPage() {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [traningdata, setTraningData] = useState(false);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openTSDialog, setOpenTSDialog] = useState(false);
  const [deletedFileName, setDeletedFileName] = useState('');
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [editFile, setEditFile] = useState(null);
  const [editMetadata, setEditMetadata] = useState('');
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  useEffect(() => {
    const fetchUserFiles = async () => {
      try {
        const data = await getUserFiles();
        setFiles(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserFiles();
  }, []);

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const handleDelete = (fileId) => {
    const deleteUserFile = async () => {
      try {
        await delUserFile(fileId);
        const deletedFile = files.find((file) => file.id === fileId);
        setFiles(files.filter((file) => file.id !== fileId));
        setDeletedFileName(deletedFile.file_name);
        setOpenDialog(true);
      } catch (error) {
        setError(error);
      }
    };

    deleteUserFile();
  };

  const handleOpenUploadDialog = () => {
    setOpenUploadDialog(true);
  };

  const handleTrainData = async () => {
    try {
      setTraningData(true);
      await TrainData();
      setOpenTSDialog(true);
    } catch (error) {
      setError(error);
    } finally {
      setTraningData(false);
    }
  };

  const handleCloseUploadDialog = () => {
    setOpenUploadDialog(false);
    setUploadFiles([]);
    window.location.reload();
  };

  const handleFileChange = (event) => {
    setUploadFiles(event.target.files);
  };

  const handleUpload = async () => {
    if (uploadFiles.length === 0) return;
    setUploading(true);
    let formData = new FormData();
    for (let i = 0; i < uploadFiles.length; i++) {
      formData.append('files', uploadFiles[i]);
    }

    try {
      const data = await UploadUserFile(formData);
      setFiles([...files, ...data]);
      handleCloseUploadDialog();
    } catch (error) {
      setError(error);
    } finally {
      setUploading(false);
    }
  };
  const handleEdit = (file) => {
    setEditFile(file);
    setEditMetadata(file.metadata || '');
    setOpenEditDialog(true);
  };

  const handleUpdateMetadata = async () => {
    if (!editFile) return;

    try {
      const updatedFile = { ...editFile, metadata: editMetadata };
      const data = await updateFileMetadata(updatedFile.id,  editMetadata );
      setFiles(files.map((file) => (file.id === updatedFile.id ? data.file : file)));
      setDialogMessage('Metadata updated successfully!');
      setSuccessDialogOpen(true);
      setOpenEditDialog(false);
    } catch (error) {
      setDialogMessage('Error updating metadata.');
      setErrorDialogOpen(true);
    } 
  };


  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleCloseSuccessDialog = () => {
    setSuccessDialogOpen(false);
  };

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
  };

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (error) {
    return <Typography variant="h6" color="error">Error loading files</Typography>;
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseTSDialog = () => {
    setOpenTSDialog(false);
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4">File Management</Typography>
        <Button variant="contained" color="primary" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenUploadDialog}>
          Upload Files
        </Button>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
        <Typography variant="h4"></Typography>
        <LoadingButton variant="contained" color="primary" loading={traningdata} onClick={handleTrainData} disabled={files.length === 0}>
          Train Data
        </LoadingButton>
      </Stack>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>File Name</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((file) => (
              <TableRow key={file.id}>
                <TableCell component="th" scope="row">
                  {file.file_name}
                </TableCell>
                <TableCell align="right">
                  <IconButton edge="end" aria-label="edit" color="primary" onClick={() => handleEdit(file)} sx={{ pr: "20px" }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="end" aria-label="delete" color="error" onClick={() => handleDelete(file.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>File Deleted</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The file "{deletedFileName}" has been successfully deleted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openUploadDialog} onClose={handleCloseUploadDialog}>
        <DialogTitle>Upload Files</DialogTitle>
        <DialogContent>
          <DialogContentText>Select files to upload.</DialogContentText>
          <TextField
            type="file"
            inputProps={{ multiple: true, accept: '.pdf, .xls, .xlsx, .docx' }}
            onChange={handleFileChange}
            fullWidth
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUploadDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpload} color="primary" disabled={uploading}>
            {uploading ? <CircularProgress size={24} /> : 'Upload'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openTSDialog} onClose={handleCloseTSDialog}>
        <DialogTitle>Train Data</DialogTitle>
        <DialogContent>
          <DialogContentText>Training successfully completed!</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTSDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit File Metadata</DialogTitle>
        <DialogContent>
          <DialogContentText>Edit the metadata for the file "{editFile?.file_name}".</DialogContentText>
          <TextField
            label="Metadata"
            value={editMetadata}
            onChange={(e) => setEditMetadata(e.target.value)}
            fullWidth
            margin="dense"
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateMetadata} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={successDialogOpen} onClose={handleCloseSuccessDialog}>
        <DialogTitle>Success</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSuccessDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={errorDialogOpen} onClose={handleCloseErrorDialog}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
