class MessageParser {
    constructor(actionProvider, state) {
      this.actionProvider = actionProvider;
      this.state = state;
    }
  
    parse(message) {
      console.log(message);
      if (message.trim() !== "") {
        this.actionProvider.handleAPIRequest(message);
      }
    }
  }
  
  export default MessageParser;  