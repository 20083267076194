import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { bgGradient } from '../theme/css';
import { ReactComponent as MySVG } from '../wowizer_logo.svg';
import { confirmEmail } from 'src/services/apis'; 
import { useRouter } from 'src/routes/hooks';
import { useParams } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function EmailConfirmView() {
  const theme = useTheme();
  const router = useRouter();
  const { token } = useParams();
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const confirmUserEmail = async () => {
      if ( token) {
        try {
          const response = await confirmEmail(token);
          if (response.status === 200) {
            setMessage('Your email has been successfully confirmed.');
          } else {
            setMessage('This link is expired or the account is already confirmed.');
          }
        } catch (error) {
          setMessage('This link is expired or the account is already confirmed.');
        }
        setOpen(true);
      }
    };
    confirmUserEmail();
  }, [ token]);

  const handleClose = () => {
    setOpen(false);
    router.push('/login');
  };

  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.9),
          imgUrl: '/assets/overlay_4.jpg',
        }),
        height: 1,
      }}
    >
      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card 
          sx={{
            p: 5,
            width: 1,
            maxWidth: 420,
          }}
        >
          <Box
            component="div"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 1,
            }}
          >
            <Typography variant="h4">
              Email Confirmation
            </Typography>
          </Box>
          <Box
            component="div"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <MySVG />
          </Box>
          <Box
            component="div"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" sx={{ mt: 2, mb: 5,textAlign:"center" }}>
              {message}
            </Typography>
          </Box>
          <Button
            fullWidth
            size="large"
            variant="contained"
            onClick={handleClose}
          >
            Go to Login
          </Button>
        </Card>

     
      </Stack>
    </Box>
  );
}
