import React, { useState, useEffect, useCallback } from 'react';
import { Box, TextField, Button, Typography, Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const EditableTable = ({ title, data, onSave, pdfData, extractedMatch }) => {
  const [tableData, setTableData] = useState(data);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (selectedRow && selectedRow.key in extractedMatch) {
      const match = extractedMatch[selectedRow.key];
      if (match.page_numbers && match.page_numbers.length > 0) {
        setPageNumber(match.page_numbers[0]);
      }
    }
  }, [selectedRow, extractedMatch]);

  const handleInputChange = (e, key) => {
    const newData = { ...tableData, [key]: e.target.value };
    setTableData(newData);
  };

  const handleSave = () => {
    onSave(title, tableData);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleEditWithPDF = (key) => {
    setSearchText(tableData[key]);
    setSelectedRow({ key, value: tableData[key], pageNumber: extractedMatch[key]?.page_numbers?.[0] || 1 });
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleDialogSave = () => {
    if (selectedRow) {
      console.log(tableData)
      const newData = { ...tableData, [selectedRow.key]: selectedRow.value };
      setTableData(newData);
      setDialogOpen(false);
    }
  };

  const handleDialogInputChange = (e) => {
    setSelectedRow({ ...selectedRow, value: e.target.value });
  };

  const handlePageClick = (page) => {
    setPageNumber(page);
  };

  const highlightPattern = (text, pattern) => {
    const modifiedPattern = pattern.replace(/_/g, ' ').replace(/\\n/g, '\n').replace(/\\/g, '').replace(/\(/g, '\\(').replace(/\)/g, '\\)');
    const hasSentences = /[.!?](\s|$)|\n/.test(modifiedPattern);
    let words;

    if (hasSentences) {
        words = modifiedPattern.split(/\n+/);
    } else {
        words = modifiedPattern.split(' ');
    }
    
    const regex = new RegExp(`(${words.join('|')})`, 'gi');
    console.log(words,modifiedPattern,regex,text)
    return text.replace(regex, (value) => `<mark>${value}</mark>`);
  };

  const textRenderer = useCallback(
    (textItem) => highlightPattern(textItem.str, searchText),
    [searchText]
  );

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h6">{title}</Typography>
      <table border="1" style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ padding: '8px', border: '1px solid #ccc' }}>Key</th>
            <th style={{ padding: '8px', border: '1px solid #ccc' }}>Value</th>
            <th style={{ padding: '8px', border: '1px solid #ccc' }}>Page Numbers</th>
            <th style={{ padding: '8px', border: '1px solid #ccc' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(tableData).map((key) => (
            <tr key={key}>
              <td style={{ padding: '8px', border: '1px solid #ccc' }}>{key}</td>
              <td style={{ padding: '8px', border: '1px solid #ccc' }}>
                <TextField
                  fullWidth
                  value={tableData[key]}
                  onChange={(e) => handleInputChange(e, key)}
                />
              </td>
              <td style={{ padding: '8px', border: '1px solid #ccc' }}>
                {extractedMatch[key]?.page_numbers.join(",") || 'N/A'}
              </td>
              <td style={{ padding: '8px', border: '1px solid #ccc' }}>
                <Button
                  variant="contained"
                  onClick={() => handleEditWithPDF(key)}
                >
                  View in PDF
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* <Button variant="contained" onClick={handleSave} sx={{ mt: 2 }}>
        Save {title}
      </Button> */}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {title} updated successfully!
        </Alert>
      </Snackbar>

      <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="lg" fullWidth>
        <DialogTitle>Edit with PDF</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: '70%', pr: 2 }}>
              <Document file={pdfData} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
                <Page pageNumber={pageNumber} customTextRenderer={textRenderer} />
              </Document>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                {selectedRow && extractedMatch[selectedRow.key]?.page_numbers.map((page, index) => (
                  <Button
                    key={index}
                    variant="outlined"
                    onClick={() => handlePageClick(page)}
                    sx={{ mx: 0.5 }}
                  >
                    Page {page}
                  </Button>
                ))}
              </Box>
            </Box>
            <Box sx={{ width: '30%' }}>
              <TextField
                label="Key"
                fullWidth
                value={selectedRow ? selectedRow.key : ''}
                disabled
                sx={{ mb: 2 }}
              />
              <TextField
                label="Value"
                fullWidth
                multiline
                value={selectedRow ? selectedRow.value.replace(/\\\\n/g, '\n') : ''}
                onChange={handleDialogInputChange}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          {/* <Button variant="contained" onClick={handleDialogSave}>Save</Button> */}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EditableTable;
