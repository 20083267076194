import React, { useState, useEffect, useCallback } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import { Document, Page, pdfjs } from 'react-pdf';
import { getSummaryData, getUserFiles } from 'src/services/apis';
import axios from 'axios';
import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-json-view-lite/dist/index.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextareaAutosize from '@mui/material/TextareaAutosize';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function SummarizerPage() {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [inputText, setInputText] = useState('');
  const [responseText, setResponseText] = useState("");
  const [isExtracting, setIsExtracting] = useState(false);
  const [extractedMatch, setExtractedMatch] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [fullResponseOpen, setFullResponseOpen] = useState(false);

  useEffect(() => {
    const fetchUserFiles = async () => {
      try {
        const data = await getUserFiles();
        setFiles(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserFiles();
  }, []);

  const highlightPattern = (text, pattern) => {
    const modifiedPattern = pattern.replace(/_/g, ' ');
    const words = modifiedPattern.split(' ');
    const regex = new RegExp(`\\b(${words.join('|')})\\b`, 'gi');
    return text.replace(regex, (value) => `<mark>${value}</mark>`);
  };

  const textRenderer = useCallback(
    (textItem) => highlightPattern(textItem.str, searchText),
    [searchText]
  );

  const handleFileSelect = async (event) => {
    const fileId = event.target.value;
    const file = files.find((file) => file.id === fileId);
    setSelectedFile(file);
    setPageNumber(1);
    if (file) {
      try {
        const response = await axios.get(file.file_url, {
          responseType: 'blob',
        });
        setPdfData(URL.createObjectURL(response.data));
      } catch (error) {
        setError(error);
      }
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const goToPreviousPage = () => {
    setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 1, 1));
  };

  const goToNextPage = () => {
    setPageNumber((prevPageNumber) => Math.min(prevPageNumber + 1, numPages));
  };

  const handlePageInputChange = (event) => {
    const pageNumber = parseInt(event.target.value, 10);
    if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= numPages) {
      setPageNumber(pageNumber);
    }
  };

  const handleSubmit = async () => {
    setIsExtracting(true);
    try {
      const data = await getSummaryData(selectedFile.id, inputText);
      setResponseText(data.summary_data);
    } catch (err) {
      // Handle error
    } finally {
      setIsExtracting(false);
    }
  };

  const handleFullResponseOpen = () => {
    setFullResponseOpen(true);
  };

  const handleFullResponseClose = () => {
    setFullResponseOpen(false);
  };

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (error) {
    return <Typography variant="h6" color="error">Error loading files</Typography>;
  }

  return (
    <Container>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ width: '50%', pr: 2 }}>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">Select a file to display:</Typography>
            <Select value={selectedFile ? selectedFile.id : ''} onChange={handleFileSelect} displayEmpty>
              <MenuItem value="" disabled>Select a file</MenuItem>
              {files.map((file) => (
                <MenuItem key={file.id} value={file.id}>{file.file_name}</MenuItem>
              ))}
            </Select>
          </Box>
          {selectedFile && (
            <Box
              sx={{
                border: '1px solid #ccc',
                borderRadius: '4px',
                overflow: 'auto',
                maxHeight: '80vh',
                boxShadow: 3,
                p: 2,
              }}
            >
              {pdfData ? (
                <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
                  <Document file={pdfData} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} customTextRenderer={textRenderer} />
                  </Document>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                    <Button onClick={goToPreviousPage} disabled={pageNumber <= 1}>Previous</Button>
                    <TextField
                      type="number"
                      value={pageNumber}
                      onChange={handlePageInputChange}
                      inputProps={{ min: 1, max: numPages }}
                      sx={{ mx: 2, width: 80 }}
                    />
                    <Typography variant="body1">{`of ${numPages}`}</Typography>
                    <Button onClick={goToNextPage} disabled={pageNumber >= numPages}>Next</Button>
                  </Box>
                </Box>
              ) : (
                <Typography variant="h6">Loading PDF...</Typography>
              )}
            </Box>
          )}
        </Box>
        {pdfData && (
          <Box sx={{ width: '50%', pl: 2 }}>
            <Typography variant="h6">Summarization</Typography>
            <TextareaAutosize
              minRows={5}
              placeholder="Enter your key points to summarize."
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              style={{ width: '100%', padding: '10px', marginBottom: '16px' }}
            />
            <Button variant="contained" onClick={handleSubmit} disabled={isExtracting}>
              {isExtracting ? <CircularProgress size={24} /> : 'Summarize'}
            </Button>
            {responseText && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1">Response:</Typography>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                  {responseText.split('\n').slice(0, 3).join('\n') + '...'}
                </ReactMarkdown>
                <Button onClick={handleFullResponseOpen}>View Full Response</Button>
                <Dialog open={fullResponseOpen} onClose={handleFullResponseClose} fullWidth maxWidth="lg">
                  <DialogTitle>Full Response</DialogTitle>
                  <DialogContent>
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {responseText}
                    </ReactMarkdown>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleFullResponseClose} color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Container>
  );
}
