import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Box, Checkbox, Paper } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import { Select, MenuItem, Alert } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ReplayIcon from '@mui/icons-material/Replay';
import * as XLSX from 'xlsx';
import { UploadMasterFile, getMasterFile, ExportProcessedForm,ProcessFormFile } from 'src/services/apis';  


export default function VendorExcelFormsPage() {
  const [masterFileUploaded, setMasterFileUploaded] = useState(false);
  const [vendorData, setVendorData] = useState([]);
  const [vendorProcessedFile, setVendorProcessedFile] = useState(null);
  const [selectedValues, setSelectedValues] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [masterFile, setMasterFile] = useState(null);
  const [vendorFile, setVendorFile] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [editingRow, setEditingRow] = useState(null);
  useEffect(() => {
    getMasterFile().then((data) => {
      if (data && data.file_exist) {
        setMasterFile(data.data.file_name.split('/').pop());
        setMasterFileUploaded(true);
      } else {
        setMasterFileUploaded(false);
      }
    });
  }, []);

  const handleMasterFileUpload = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('files', file);

    setIsUploading(true);
    try {
      const response = await UploadMasterFile(formData);
      setMasterFile(response.file_name.split('/').pop());
      setMasterFileUploaded(true);
    } catch (error) {
      console.error('Error uploading master file:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleVendorFileSelect = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      setVendorFile(file);
      setFileError(null);
    } else {
      setVendorFile(null);
      setFileError('Please upload a valid .xlsx file');
    }
  };

  const handleProcessVendorFile = async () => {
    if (!vendorFile) {
      alert('Please select a Vendor Xlsx Form to process.');
      return;
    }

    if (!masterFile) {
      alert('Master file not found. Please upload a master file first.');
      return;
    }

    const formData = new FormData();
    formData.append('files', vendorFile);
    formData.append('sel_mfile', masterFile);  // Adding the master file name

    setIsUploading(true);
    try {
      const response = await ProcessFormFile(formData); 
      if (response.success) {
        let formdata=response.form_data;
        Object.keys(formdata).map((key, index) => {
          formdata[key]['include']=true
        })
        setVendorData(formdata);
        setVendorProcessedFile(response.form_file_path);
        alert('Form processed and data uploaded successfully.');
      } else {
        alert('Failed to process the form.');
      }
    } catch (error) {
      console.error('Error processing vendor file:', error);
      alert('An error occurred while processing the file.');
    } finally {
      setIsUploading(false);
    }
  };
  const handleCheckboxChange = (key) => {
    setVendorData((prevVendorData) => ({
      ...prevVendorData,
      [key]: {
        ...prevVendorData[key],
        include: !prevVendorData[key].include, // Toggle the include field
      },
    }));
  };
  const handleSelectChange = (key, value) => {
    setSelectedValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleExportToExcel = async() => {
    if (!vendorData || Object.keys(vendorData).length === 0) {
      alert('No data available to export.');
      return;
    }
  
    const updatedVendorData = JSON.parse(JSON.stringify(vendorData));

    Object.keys(updatedVendorData).forEach((key) => {
      if (updatedVendorData[key]['include']){
        updatedVendorData[key].values =selectedValues[key]? [selectedValues[key]]: [updatedVendorData[key].values[0]];
      }else
      {
        delete updatedVendorData[key]
      }
    });
    let formData={
      "form_file_path":vendorProcessedFile,
      "form_data":updatedVendorData
    }

    try {
      const response = await ExportProcessedForm(formData); // Assuming you have an API function to handle export
      if (response.data) {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'extracted_file.xlsx';
        if (contentDisposition) {
          const matches = /filename=([^;]+)/.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].trim();
          }
        }
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        console.log(fileName)
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        alert('Failed to export data.');
      }
    } catch (error) {
      console.error('Error exporting to Excel:', error);
      alert('An error occurred while exporting the data.');
    }
  };

  const handleEditClick = (key) => {
    setEditingRow(key);
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    setEditingRow(null); 
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4">Xlsx Forms Processing</Typography>
        {masterFileUploaded ? (
          <Box component={Paper} p={2} display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Typography variant="body1" color="textSecondary">
              Uploaded Master File: <strong>{masterFile}</strong>
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              component="label"
              startIcon={<ReplayIcon />}
              disabled={isEditing || isUploading}
            >
              {isUploading ? <CircularProgress size={24} /> : 'Reupload Master File'}
              <input type="file" hidden onChange={handleMasterFileUpload}  accept=".xlsx"/>
            </Button>
          </Box>
        ) : (
          <Button
            variant="contained"
            color="primary"
            component="label"
            startIcon={<CloudUploadIcon />}
            disabled={isEditing || isUploading}
          >
            {isUploading ? <CircularProgress size={24} /> : 'Upload Master Data sheet'}
            <input type="file" hidden onChange={handleMasterFileUpload}  accept=".xlsx"/>
          </Button>
        )}
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Box display="flex" alignItems="center" gap={2}>
          <Button
            variant="contained"
            color="primary"
            component="label"
            startIcon={<CloudUploadIcon />}
            disabled={isEditing}
          >
            Upload Vendor Xlsx Form
            <input
              type="file"
              hidden
              accept=".xlsx"
              onChange={handleVendorFileSelect}
            />
          </Button>
          {vendorFile && (
            <Typography variant="body2" color="textSecondary">
              Selected File: {vendorFile.name}
            </Typography>
          )}
        </Box>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleProcessVendorFile}
            disabled={isEditing || !vendorFile}
          >
            Process Form
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleExportToExcel}
            disabled={isEditing}
          >
            Export to Excel
          </Button>
        </Stack>
      </Stack>

      {fileError && (
        <Alert severity="error" mb={3}>
          {fileError}
        </Alert>
      )}
<TableContainer component={Paper} elevation={3} sx={{ maxHeight: 400 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ maxWidth: 30 }}><strong>Include?</strong></TableCell>
              <TableCell><strong>Key</strong></TableCell>
              <TableCell><strong>Value</strong></TableCell>
              <TableCell align="center"><strong>Actions</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(vendorData).map((key, index) => (
              <TableRow key={index} hover>
                <TableCell sx={{ maxWidth: 30 }} >
                    <Checkbox
                checked={vendorData[key].include}
                onChange={() => handleCheckboxChange(key)}
                disabled={!(editingRow === key)}
                    />
                </TableCell>
                <TableCell sx={{ maxWidth: 150 }}>{key}</TableCell>
                <TableCell sx={{ maxWidth: 250 }}>
                  <Select
                    value={selectedValues[key] || vendorData[key].values[0]}
                    onChange={(e) => handleSelectChange(key, e.target.value)}
                    disabled={!(editingRow === key)}
                    fullWidth
                    variant="outlined"
                    size="small"
                  >
                    {vendorData[key].values.map((option, idx) => (
                      <MenuItem key={idx} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell align="center">
                  {editingRow === key ? (
                    <IconButton onClick={() => handleSaveClick(key)} color="primary">
                      <SaveIcon />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => handleEditClick(key)} color="primary">
                      <EditIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
