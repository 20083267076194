import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Iconify from 'src/components/iconify';
import Chatbot from 'react-chatbot-kit';
import config from 'src/components/config';
import ActionProvider from 'src/components/ActionProvider';
import MessageParser from 'src/components/MessageParser';
import 'react-chatbot-kit/build/main.css';

const customStyles = `
/* Reset some basic elements */
body, html, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* Basic body styling */
body {
  line-height: 1;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}

/* Chatbot container */
.react-chatbot-kit-chat-container {
  margin-left: 3%;
  width: 90%;
  height: 80vh;
  max-width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Chat header */
.react-chatbot-kit-chat-header {
  background-color: #007bff;
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

/* Chat message container */
.react-chatbot-kit-chat-messages {
  padding: 10px;
  background-color: white;
  height: 50vh;
  overflow-y: auto;
}

/* Chat input area */
.react-chatbot-kit-chat-input {
  display: flex;
  padding: 15px;
  background-color: #eee;
}

.react-chatbot-kit-chat-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.react-chatbot-kit-chat-input button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-left: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

/* Messages */
.react-chatbot-kit-chat-message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
  background-color: #f1f1f1;
}

.react-chatbot-kit-chat-message.user {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
}

.react-chatbot-kit-chat-bot-message {
  margin-left: 0px;
  width: 50%;
}

.react-chatbot-kit-chat-message.bot {
  background-color: #e9ecef;
  align-self: flex-start;
}
`;

function injectCustomStyles() {
  const style = document.createElement('style');
  style.textContent = customStyles;
  document.head.append(style);
}

injectCustomStyles();

// ----------------------------------------------------------------------

export default function ChatbotPage() {
  return (
    <Container>
      <br />
      <Chatbot  config={config} 
                messageParser={MessageParser} 
                placeholderText="You can ask any questions regarding your uploaded data, and I'll provide the best possible answers."
                actionProvider={ActionProvider}/>    
    </Container>
  );
}