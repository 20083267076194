import apiClient from './apiClient';

export const getAccessToken = () => {
  let accessToken=localStorage.getItem('accessToken');
  if (!accessToken) {
    throw new Error('No access token found');
  }
  return accessToken
};

export const getRefreshToken = () => {
  return localStorage.getItem('refreshToken');
};

export const setAccessToken = (token) => {
  localStorage.setItem('accessToken', token);
};

export const setRefreshToken = (token) => {
  localStorage.setItem('refreshToken', token);
};

export const removeTokens = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
};

export const refreshToken = async () => {
  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    throw new Error('No refresh token found');
  }

  try {
    const response = await apiClient.post('/token/refresh/', { refresh: refreshToken });
    const { access} = response.data;
    setAccessToken(access);
    return access;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};

export const checkAndRefreshToken = async () => {
  const accessToken = getAccessToken();
  if (!accessToken) {
    return await refreshToken();
  }
  // Add logic to check if the access token is expired (this depends on your token structure)
  // Assuming JWT, you can decode and check the expiry
  const tokenParts = accessToken.split('.');
  if (tokenParts.length !== 3) {
    throw new Error('Invalid access token');
  }
  const payload = JSON.parse(atob(tokenParts[1]));
  const expiry = payload.exp * 1000;
  const now = Date.now();

  if (expiry < now) {
    return await refreshToken();
  }
  return accessToken;
};

export const Register = async (formData) => {
  try {
    const response = await apiClient.post('/registration/', formData);
    return response.data;
  } catch (error) {
    console.error('Error in user registration:', error);
    throw error;
  }
};

export const Adduser = async (formData) => {
  const accessToken = await checkAndRefreshToken();
  console.log(formData)
  try {
    const response = await apiClient.post('/create-user-subs/', formData,{
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error in user addition:', error);
    throw error;
  }
};

export const UpdateAccStatus = async (data) => {
  const accessToken = await checkAndRefreshToken();
  try {
    const response = await apiClient.post('/acc_status/', data,{
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error in user updating account status:', error);
    throw error;
  }
};


export const login = async (username, password) => {
  const response = await apiClient.post('/login', { username, password });
  const { accessToken, refreshToken } = response.data;
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
  return response.data;
};

export const getUserDetails = async () => {
  const accessToken = await checkAndRefreshToken();
  try {
    const response = await apiClient.get('/user_details/', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user details:', error);
    throw error;
  }
};

export const getUserList = async () => {
  const accessToken = await checkAndRefreshToken();
  try {
    const response = await apiClient.get('/user_list/', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user details:', error);
    throw error;
  }
};

export const resetPassword = async (email) => {
  try {
    const response = await apiClient.post('/password/reset/', {
      email:email
    });
    return response.data;
  } catch (error) {
    console.error('Error password reset email sending failed:', error);
    throw error;
  }
};

export const resetPasswordVerify = async (userId, token, password) => {
  try {
    const response = await apiClient.post('/password/reset/confirm/', {
      "new_password1":password,
      "new_password2":password,
      "token":token,
      "uid":userId
    });
    return response.data;
  } catch (error) {
    console.error('Error password reset:', error);
    throw error;
  }
};


export const chatApi = async (data) => {
  try {
    const accessToken = await checkAndRefreshToken();

    const response = await apiClient.post('/chat/', data,{
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (error) {
    console.error('Error chat api:', error);
    throw error;
  }
};



export const confirmEmail = async (token) => {
  try {
    const response = await apiClient.post('/account-confirm-email/', {
      "key":token
    });
    return response;
  } catch (error) {
    console.error('Error email confirmation:', error);
    throw error;
  }
};
export const getUserFiles = async () => {
  const accessToken = await checkAndRefreshToken();
  try {
    const response = await apiClient.get('/files/', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user files:', error);
    throw error;
  }
};

export const delUserFile = async (fileId) => {
  const accessToken = await checkAndRefreshToken();
  try {
    const response = await apiClient.delete('/files/delete', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        file_id: fileId,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting user files:', error);
    throw error;
  }
};

export const UploadUserFile = async (formData) => {
  const accessToken = await checkAndRefreshToken();
  try {
    const response = await apiClient.post('/files/upload', formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading user files:', error);
    throw error;
  }
};


export const updateFileMetadata = async (fileId,fileMetadata) => {
  const accessToken = await checkAndRefreshToken();
  try {
    const response = await apiClient.post('/files/metadata', {
      
        file_id: fileId,
        file_metadata:fileMetadata
  
    },{
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating file metadata:', error);
    throw error;
  }
};
export const TrainData = async () => {
  const accessToken = await checkAndRefreshToken();
  try {
    const response = await apiClient.post('/mltraining/', {}, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error Training Data on user files:', error);
    throw error;
  }
};

export const UpdateUserContext = async (userId,userContextInfo) => {
  const accessToken = await checkAndRefreshToken();
  try {
    const response = await apiClient.post('/user_context/', {
      
      "user_id":userId,
      "context_info":userContextInfo
  
    },{
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating file metadata:', error);
    throw error;
  }
};


export const getExtractedData = async (fileId,json_text) => {
  const accessToken = await checkAndRefreshToken();
  try {
    const response = await apiClient.post('/extract_data/', {
      
      "file_id":fileId,
      "json_text":json_text
  
    },{
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error extract_data:', error);
    throw error;
  }
};


export const getExtractedFileDownload = async (jsondata) => {
  const accessToken = await checkAndRefreshToken();
  try {
    const response = await apiClient.post('/export_excel_file/', {
      
    "final_data":jsondata
  
    },{
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: 'blob',
    });
    return response;
  } catch (error) {
    console.error('Error extract_data:', error);
    throw error;
  }
};


export const getExtractedJson = async (pdf_file_id,exl_file_id) => {
  const accessToken = await checkAndRefreshToken();
  try {
    const response = await apiClient.post('/extract_excel_file/', {
      
    "exl_file_id":exl_file_id,
    "pdf_file_id":pdf_file_id
  
    },{
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });
    return response;
  } catch (error) {
    console.error('Error extract_data:', error);
    throw error;
  }
};


export const getSummaryData = async (fileId,userPrompt) => {
  const accessToken = await checkAndRefreshToken();
  try {
    const response = await apiClient.post('/file_summary/', {
      
      "file_id":fileId,
      "user_prompt":userPrompt
    },{
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error extract_data:', error);
    throw error;
  }
};



export const getMasterFile = async () => {
  const accessToken = await checkAndRefreshToken();
  try {
    const response = await apiClient.get('/master_file/',{
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return {file_exist:true,data:response.data};
  } catch (error) {
    // console.error('Error extract_data:', error);
    return {file_exist:false};
  }
};

export const UploadMasterFile = async (formData) => {
  const accessToken = await checkAndRefreshToken();
  try {
    const response = await apiClient.post('/master_file/upload', formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading master file:', error);
    throw error;
  }
};


export const ProcessFormFile = async (formData) => {
  const accessToken = await checkAndRefreshToken();
  try {
    const response = await apiClient.post('/process_form_data/', formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error in processing :', error);
    throw error;
  }
};



export const ExportProcessedForm = async (excel_data) => {
  const accessToken = await checkAndRefreshToken();
  try {
    const response = await apiClient.post('/export_form_file/',excel_data ,{
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: 'blob',
    });
    return response;
  } catch (error) {
    console.error('Error updating file metadata:', error);
    throw error;
  }
};